import React from 'react';
import background from './assets/images/image2.jpg';
import logo from './assets/images/logoLight.png';
import UnderConstruction from './components/UnderConstruction';
import { FillingBottle } from "react-cssfx-loading";
import fbImg from './assets/images/fb.png';
import igImg from './assets/images/ig.png';
import liImg from './assets/images/li.png';
import mailImg from './assets/images/mail.png';

const App = () => (
  <UnderConstruction
    background={{
      image: background,
      textColor: '#fff',
      // overlay: {
      //   color: '#000',
      //   opacity: '.7'
      // }
    }}
    logo={{
      src: logo,
      alt: 'logo',
      style: {
        width: '300px'
      }
    }}
    description={{
      text: 'Our website is under construction. We\'ll be here soon',
      style: {
        maxWidth: '440px',
      }
    }}
    loader={
      <FillingBottle style={{
        marginTop: '120px'
      }} color="#e1a33b" width="60px" height="60px" duration="3s" />}
    links={[
      {
        url: 'https://www.facebook.com/Ghostprod.net',
        image: fbImg,
      },
      {
        url: 'https://www.instagram.com/ghostprod_officiel',
        image: igImg,
      },
      {
        url: 'https://www.linkedin.com/company/ghostprod/about',
        image: liImg,
      },
      {
        url: 'mailto:contact@ghostprod.net',
        image: mailImg,
      },
    ]}
  />
);

export default App;
